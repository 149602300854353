<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert :active.sync="user_not_found" color="danger" title="User Not Found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" v-if="user_data">

      <vx-card class="mb-base" title="Account">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="user_data.photoURL" class="rounded w-full"/>
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">First Name</td>
                <td>{{ user_data.first_name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ user_data.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Role</td>
                <td>{{ user_data.role | title('_') }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Last Name</td>
                <td>{{ user_data.last_name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Company</td>
                <td>{{ user_data.company }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button :to="{name: 'app-user-edit', params: { userId: $route.params.userId }}"
                       icon="icon-edit"
                       icon-pack="feather">Edit
            </vs-button>
            <vs-button @click="suspend_user_popup = !suspend_user_popup" color="danger" icon="icon-user-x" icon-pack="feather" class="ml-4 mr-4">
              Suspend
            </vs-button>
            <vs-button @click="unsuspendUser" color="success" icon="icon-user-plus" icon-pack="feather">
              Un-Suspend
            </vs-button>
          </div>

        </div>

      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Information">
            <table>
              <tr>
                <td class="font-semibold">Birth Date</td>
                <td>{{ user_data.dob }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Mobile</td>
                <td>{{ user_data.phone_number }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Website</td>
                <td>{{ user_data.website }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Language</td>
                <td>{{ user_data.language }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Gender</td>
                <td>{{ user_data.gender }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
    </div>

    <!-- Suspend User Prompt --->
    <vs-prompt
      title="Confirm Suspension"
      acceptText="Suspend"
      @accept="suspendUser"
      @close="exitSuspendUserPopup"
      :is-valid="validSuspendReason"
      :active.sync="suspend_user_popup">
      <div class="con-exemple-prompt">
        Please Enter A Reason For Suspension
        <vs-input placeholder="Suspension Reason" v-model="suspend_user_reason" class="mt-4 mb-3 w-full" />
        <vs-alert  v-if="!validSuspendReason" color="danger" vs-icon="new_releases" >
          Please enter a reason above 5 characters.
        </vs-alert>
      </div>
    </vs-prompt>

  </div>
</template>

<script>

export default {
  data() {
    return {
      user_data: null,
      user_not_found: false,
      suspend_user_popup: false,
      suspend_user_reason: ''
    };
  },
  methods: {
    suspendUser() {
      this.$http.post(`users/${this.user_data.id}/suspend`)
        .then(response => {
          if (response.data.data) {
            this.$vs.notify({
              color: 'success',
              title: 'User Suspended',
              text: `${this.user_data.first_name} ${this.user_data.last_name} was suspended`,
            });
          }
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'User Not Suspended',
            text: 'An error occurred while suspending the user',
          });
        });
    },
    unsuspendUser() {
      this.$http.delete(`users/${this.user_data.id}/suspend`)
        .then(response => {
          if (response.data.data) {
            this.$vs.notify({
              color: 'success',
              title: 'User Un-Suspended',
              text: `${this.user_data.first_name} ${this.user_data.last_name} was un-suspended`,
            });
          }
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'User Not Un-Suspended',
            text: 'An error occurred while un-suspending the user',
          });
        });
    },
    exitSuspendUserPopup() {
      this.suspend_user_reason = ''
    }
  },
  computed:{
    validSuspendReason(){
      return this.suspend_user_reason.length > 5 && this.suspend_user_reason !== '';
    }
  },
  created() {

    const userId = this.$route.params.userId;

    this.$http.get(`users/${userId}`)
      .then(response => {
        if (response.data.data) {
          this.user_data = response.data.data;
          const pictureId = this.user_data.profile_picture || 'default';
          this.user_data.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
        }

      })
      .catch(error => {
        if (error.response.status === 404) {
          this.user_not_found = true;
          return;
        }
        console.error(error);
      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
